import { Builder, BuilderComponent } from '@builder.io/react'
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import DefaultErrorPage from 'next/error'
import Head from 'next/head'
import { useRouter } from 'next/router'
// Lazyload these if you we ever actually need them https://github.com/BuilderIO/builder/tree/main/packages/widgets
//import '@builder.io/widgets'
import getBuilderPageContent from '../../../src/common/queries/getBuilderPageContent'
import getBuilderPromoBanners from '../../../src/common/queries/getBuilderPromoBanners'
import { getBuilderBannersOrPromos } from '@/src/common/queries/getBuilderBannersOrPromos'
import '@/src/builderCustomComponentImports'
import { findBySubdomain } from '@/prisma/repository/whiteLabelRepository'
import { getWhiteLabelProgramConfigObjectFromProgram } from '@/src/modules/whiteLabelProgram/whiteLabelProgram.service'
import { ParsedUrlQuery } from 'querystring'

export interface WhiteLabelPageParams extends ParsedUrlQuery {
    site: string
    page: string[]
}

export async function getWhiteLabelPageStaticProps({
    params,
}: GetStaticPropsContext<WhiteLabelPageParams>) {
    const page = params?.page ?? []
    const site = params?.site ?? ''

    let whiteLabelProgram
    let whiteLabelProgramConfig

    whiteLabelProgram = await findBySubdomain(site) // '' should result in nothing... will return notFound
    if (!whiteLabelProgram) {
        return {
            notFound: true,
        }
    }

    whiteLabelProgramConfig =
        getWhiteLabelProgramConfigObjectFromProgram(whiteLabelProgram)
    const pagePathArray = site ? ['_sites', site, ...page] : page

    const { content } = await getBuilderPageContent({
        params: { page: pagePathArray },
        whiteLabelProgramConfig: whiteLabelProgramConfig ?? undefined,
    })
    const { corporateBanner } = await getBuilderPromoBanners()
    const { consumerData: consumerBanner } =
        await getBuilderBannersOrPromos(true)

    const whiteLabelProgramData = {
        name: whiteLabelProgram?.name ?? null,
        program_config:
            getWhiteLabelProgramConfigObjectFromProgram(whiteLabelProgram),
    }

    return {
        props: {
            content,
            consumerBanner,
            corporateBanner,
            showHeader: true,
            whiteLabelProgram: whiteLabelProgramData,
        },
        revalidate: true,
        notFound: !content,
    }
}

export default function WhiteLabelPage(
    props: InferGetStaticPropsType<typeof getWhiteLabelPageStaticProps>
) {
    const router = useRouter()
    if (router.isFallback) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: '75vh',
                }}
            >
                <h1>Loading...</h1>
            </div>
        )
    }

    const isLive = !Builder.isEditing && !Builder.isPreviewing
    if (!props?.content && isLive) {
        return (
            <>
                <Head>
                    <meta name="robots" content="noindex" />
                    <meta name="title" />
                </Head>
                <DefaultErrorPage statusCode={404} />
            </>
        )
    }

    return (
        <>
            <BuilderComponent model="page" content={props.content} />
        </>
    )
}
