import { builder } from '@builder.io/react'

// fetches active banners and promotional items from builder
export async function getBuilderBannersOrPromos(isFetchingBanners: boolean) {
    const today = new Date()

    const filters = isFetchingBanners
        ? {
              isBanner: true,
          }
        : { isPromoItem: true }

    const bannersAndPromos = await builder.getAll('banners', {
        query: {
            data: {
                enabled: true,
                ...filters,
            },
        },
    })

    const activeItems = bannersAndPromos
        ?.filter((item) => {
            const hasDates: boolean =
                !!item?.data?.endDate && !!item?.data?.startDate
            let isActive: boolean = false

            if (!!item?.data?.startDate && !!item?.data?.endDate) {
                const startDate = new Date(item.data.startDate)
                const endDate = new Date(item.data.endDate)

                isActive = Boolean(startDate <= today && endDate >= today)
            }

            return !hasDates || isActive
        })
        .sort((a, b) => {
            return a?.data?.priority > b?.data?.priority ? 1 : -1
        })

    // builds data required for website banners
    if (isFetchingBanners) {
        const data = activeItems?.length
            ? activeItems.map((item, index) => {
                  return {
                      bannerId: `banner-item-${index}`,
                      bannerText: item?.data?.bannerText ?? '',
                      buttonUrl: item?.data?.linkUrl ?? '',
                      buttonText: item?.data?.buttonText ?? '',
                      promoCode: item?.data?.promoCode ?? '',
                      promoTerms: item?.data?.promoTerms ?? '',
                      icon: item?.data?.icon?.value?.data?.svgFile ?? '',
                  }
              })
            : null

        return { consumerData: data }
    }

    // builds data required for promotional items
    const data = activeItems?.length
        ? activeItems.map((item, index) => {
              return {
                  promoId: `promo-item-${index}`,
                  promotionHeader: item?.data?.promotionHeader ?? '',
                  linkUrl: item?.data?.linkUrl ?? '',
                  promoCode: item?.data?.promoCode ?? '',
                  promoTerms: item?.data?.promoTerms ?? '',
                  image: item?.data?.image ?? '',
                  startDate: new Date(item?.data?.startDate).toISOString(),
                  endDate: new Date(item?.data?.endDate).toISOString(),
              }
          })
        : null

    return { consumerData: data }
}

export default getBuilderBannersOrPromos
