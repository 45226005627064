import { builder } from '@builder.io/react'
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
// Lazyload these if you we ever actually need them https://github.com/BuilderIO/builder/tree/main/packages/widgets
//import '@builder.io/widgets'
import '@/src/builderCustomComponentImports'
import {
    findBySubdomain,
    findProgramConfigBySubdomain,
} from '@/prisma/repository/whiteLabelRepository'
import {
    getWhiteLabelProgramSitesforStaticPaths,
    getStaticPathsForPrivateLabelMarketingPage,
} from '@/src/modules/private-label-partner-site/getStaticPathsForPrivateLabelMarketingPage'
import WhiteLabelPage, {
    getWhiteLabelPageStaticProps,
    WhiteLabelPageParams,
} from './indexWhiteLabel'
import PrivateLabelLandingPage, {
    getPrivateLabelStaticProps,
} from './indexPrivateLabel'
import { ParsedUrlQuery } from 'querystring'
import PrivateLabelPageProps from '../../../src/common/components/privateLabelPartnerSite/PrivateLabelPageProps'

const SUBDOMAINED_PAGE_TYPES = {
    whiteLabel: 'whiteLabel',
    privateLabel: 'privateLabel',
}

interface PathProps extends ParsedUrlQuery {
    site: string
}

export async function getStaticProps({
    params,
}: {
    params: { site: string; page: string[] } | PathProps | undefined
}) {
    if (params?.site) {
        const whiteLabelProgram = await findBySubdomain(params?.site)
        if (whiteLabelProgram) {
            const whiteLabelProps = await getWhiteLabelPageStaticProps({
                params,
            } as GetStaticPropsContext<WhiteLabelPageParams>) // Get the WhiteLabel props
            return {
                ...whiteLabelProps,
                props: {
                    ...(whiteLabelProps.props || {}), // Ensure all WhiteLabel props are passed
                    type: SUBDOMAINED_PAGE_TYPES.whiteLabel, // we will use this to determine which component to render
                },
            }
        } else {
            // It's probably private label page. Will also fail to 404 if it's not
            const privateLabelProps = await getPrivateLabelStaticProps({
                params,
            } as GetStaticPropsContext<PathProps>) // Get the PrivateLabel props

            return {
                ...privateLabelProps,
                props: {
                    ...(privateLabelProps.props || {}), // Ensure all PrivateLabel props are passed
                    type: SUBDOMAINED_PAGE_TYPES.privateLabel, // we will use this to determine which component to render
                },
            }
        }
    }
}

export async function getStaticPaths() {
    const limit = 100
    let offset = 0
    const paths: { params: { site: string; page: string[] } }[] = []

    const excludeUrlRegexes = [
        /\/virtual-visa-gift-cards$/,
        /\/personalized-visa-gift-card$/,
        /\/predesigned-visa-gift-card$/,
        /^\/corporate\/brands$/,
        /^\/brands/,
        /^\/visa-gift-card-box$/,
        /^\/redeem\/virtual-card$/,
        /^\/perfectgift-plus\/build$/,
        /^\/test\/refer-a-friend$/,
        /^\/login\/refer-a-friend$/,
        /^\/promotions$/,
        /^\/blog$/,
        /^\/gift-guides$/,
    ]

    // Fetch pages for each site
    const sites = await getWhiteLabelProgramSitesforStaticPaths()

    for (const site of sites.paths) {
        const programConfig = await findProgramConfigBySubdomain(
            site.params.site
        )

        // Fetch pages for whitelabel sites form builder.io
        if (programConfig.builder_public_api_key) {
            /* We'll change the api key to the whitelabel program's builder public key and
              get all the pages for this whitelabel site. We'll set the API key back when we're done.
              Believe it or not this seems to work but if better ideas exist, let's implement it. */
            builder.apiKey = programConfig.builder_public_api_key || ''
            offset = 0

            const sitePages = await builder.getAll('page', {
                options: {
                    noTargeting: true,
                    offset,
                    limit,
                },
                omit: 'data.blocks',
                limit,
            })

            while (sitePages.length === limit + offset) {
                offset += sitePages.length
                const thesePages = await builder.getAll('page', {
                    options: {
                        noTargeting: true,
                        offset,
                        limit,
                    },
                    omit: 'data.blocks',
                    limit,
                })
                sitePages.push(...thesePages)
            }

            const filteredSitePages = sitePages.filter((page) => {
                for (const regex of excludeUrlRegexes) {
                    if (page?.data?.url.match(regex)) {
                        return false
                    }
                }
                return true
            })

            filteredSitePages.forEach((page) => {
                paths.push({
                    params: {
                        site: site.params.site,
                        page: page.data?.url.split('/').filter(Boolean) || [],
                    },
                })
            })
        }
    }

    // Fetch paths for private label marketing pages
    const privateLabelPaths = await getStaticPathsForPrivateLabelMarketingPage()
    paths.push(...privateLabelPaths.paths)

    builder.apiKey = process.env.NEXT_PUBLIC_BUILDER_API_KEY || ''

    return {
        paths,
        fallback: 'blocking',
    }
}

export default function Page(
    props: InferGetStaticPropsType<typeof getStaticProps>
) {
    if (props.type === SUBDOMAINED_PAGE_TYPES.whiteLabel) {
        const { type, ...whiteLabelProps } = props // Remove type and pass correct props
        return (
            <WhiteLabelPage
                {...(whiteLabelProps as InferGetStaticPropsType<
                    typeof getWhiteLabelPageStaticProps
                >)}
            />
        )
    } else {
        const { type, ...privateLabelProps } = props // Remove type and pass correct props
        return (
            <PrivateLabelLandingPage
                {...(privateLabelProps as PrivateLabelPageProps)}
            />
        )
    }
}
