import { builder } from '@builder.io/react'
import { GetStaticPropsContext } from 'next'
import { WhiteLabelProgramConfig } from '@/src/modules/whiteLabelProgram/types'

export async function getPageAndPromoBannerContent({
    params,
    whiteLabelProgramConfig,
}: GetStaticPropsContext<{ page: string[] }> & {
    whiteLabelProgramConfig?: WhiteLabelProgramConfig
}) {
    const apiKey =
        whiteLabelProgramConfig?.builder_public_api_key ||
        process.env.NEXT_PUBLIC_BUILDER_API_KEY
    builder.apiKey = apiKey ?? ''
    builder.init(apiKey ?? '')
    const content =
        (await builder
            .get('page', {
                userAttributes: {
                    urlPath: '/' + (params?.page?.join('/') || ''),
                },
                includeRefs: true,
                cachebust: true,
                options: {
                    enrich: true,
                    noTraverse: false,
                },
            })
            .toPromise()) || null
    builder.apiKey = process.env.NEXT_PUBLIC_BUILDER_API_KEY ?? ''
    return {
        content,
    }
}

export default getPageAndPromoBannerContent
