import { builder } from '@builder.io/react'

export async function getBuilderPromoBanners() {
    const promoBanners = await builder.getAll('promo-banners', {
        // You can use options for queries, sorting, and targeting here
        // https://github.com/BuilderIO/builder/blob/main/packages/core/docs/interfaces/GetContentOptions.md
    })

    const consumerBanner = promoBanners.find((banner) => {
        return banner?.data?.corporate === false
    })
    const corporateBanner = promoBanners.find((banner) => {
        return banner?.data?.corporate === true
    })

    return {
        consumerBanner,
        corporateBanner,
    }
}

export default getBuilderPromoBanners
